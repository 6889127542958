<script>
import get from 'lodash/get';
import debounce from 'lodash/debounce';
import includes from 'lodash/includes';
import sortBy from 'lodash/sortBy';
import { mapActions, mapMutations, mapState } from 'vuex';
import { MuiApiList } from '@emobg/vue-internal';
import { DELAY, navigationErrorHandler } from '@emobg/web-utils';

import permissionsMixin from '@domains/Main/mixins/permissionsMixin';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';

import { CARRENTAL_PERMISSIONS } from '../../const/permissions';

import contentCells from './config/contentCells';
import carRental from '../../store/CarrentalModuleMap';
import services from '../../router/ServicesRouterMap';

export default {
  name: 'AlertTriggersView',
  components: { MuiApiList },
  mixins: [permissionsMixin],
  computed: {
    ...mapState(carRental.alertsTrigger.list, {
      triggerList: state => state.list.data,
      isLoading: state => state.list.STATUS.LOADING,
      isDeleting: state => state.delete.STATUS.LOADING,
    }),
    ...mapState(DOMAINS_MODEL.app.userAccount, {
      activeOperatorUuid: state => get(state, 'operators.active.uuid'),
    }),
  },
  watch: {
    activeOperatorUuid() {
      this.getAlertTriggerList({ operatorId: this.activeOperatorUuid });
      this.updateQuery({ operatorId: this.activeOperatorUuid });
    },
  },
  created() {
    this.CARRENTAL_PERMISSIONS = CARRENTAL_PERMISSIONS;
    this.getAlertTriggerList({ operatorId: this.activeOperatorUuid });
    this.updateQuery({ operatorId: this.activeOperatorUuid });
    this.contentCells = contentCells;
    this.fetchFilteredListDebounced = debounce(query => {
      if (!query.length || query.length > 2) {
        const filter = {
          query,
          operatorId: this.activeOperatorUuid,
        };

        this.updateQuery(filter);
        this.getAlertTriggerList(filter);
      }
    }, DELAY.medium);
  },
  methods: {
    ...mapActions(carRental.alertsTrigger.list, [
      'getAlertTriggerList',
    ]),
    ...mapMutations(carRental.alertsTrigger.list, [
      'updateQuery',
    ]),
    includes,
    sortBy,
    goToSetupAlertTrigger() {
      this.$router.push({ name: services.alertsTrigger.new }).catch(navigationErrorHandler);
    },
    fetchFilteredList(query = '') {
      this.fetchFilteredListDebounced(query);
    },
  },
};
</script>

<template>
  <div
    class="AlertTriggersView px-2 mt-3 px-lg-5"
    data-test-id="alert_triggers-view"
  >
    <h1 class="pb-4 d-flex justify-content-between">
      Alert triggers

      <ui-button
        v-if="includes(permissions, CARRENTAL_PERMISSIONS.configurableAlertsAddTrigger)"
        data-test-id="setup_alert-button"
        @clickbutton="goToSetupAlertTrigger"
      >
        Setup alert
      </ui-button>
    </h1>
    <MuiApiList
      data-test-id="list"
      :data-set="sortBy(triggerList, 'id')"
      :content-cells="contentCells"
      :loading="isLoading || isDeleting"
      :search="true"
      no-data-label="No configurable alerts created"
      @update:query="fetchFilteredList"
    />
  </div>
</template>
