var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "AlertTriggersView px-2 mt-3 px-lg-5",
      attrs: { "data-test-id": "alert_triggers-view" },
    },
    [
      _c(
        "h1",
        { staticClass: "pb-4 d-flex justify-content-between" },
        [
          _vm._v(" Alert triggers "),
          _vm.includes(
            _vm.permissions,
            _vm.CARRENTAL_PERMISSIONS.configurableAlertsAddTrigger
          )
            ? _c(
                "ui-button",
                {
                  attrs: { "data-test-id": "setup_alert-button" },
                  on: { clickbutton: _vm.goToSetupAlertTrigger },
                },
                [_vm._v(" Setup alert ")]
              )
            : _vm._e(),
        ],
        1
      ),
      _c("MuiApiList", {
        attrs: {
          "data-test-id": "list",
          "data-set": _vm.sortBy(_vm.triggerList, "id"),
          "content-cells": _vm.contentCells,
          loading: _vm.isLoading || _vm.isDeleting,
          search: true,
          "no-data-label": "No configurable alerts created",
        },
        on: { "update:query": _vm.fetchFilteredList },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }