import sortBy from 'lodash/sortBy';
import map from 'lodash/map';

import { sentenceCase } from '@emobg/web-utils';

import ItemListComponent from '../components/ItemListComponent';
import StatusToggle from '../components/StatusToggle';
import DeleteTrigger from '../components/DeleteTrigger';
import TriggerLink from '../components/TriggerLink';

const contentCells = [
  {
    title: 'Name',
    width: 350,
    component: TriggerLink,
    props: result => ({ result }),
  },
  {
    title: 'Vehicle category',
    width: 150,
    component: ItemListComponent,
    props: result => ({
      result,
      items: sortBy(result.categories),
    }),
  },
  {
    title: 'Station',
    width: 250,
    component: ItemListComponent,
    props: result => ({
      result,
      items: sortBy(map(result.stations, 'id')),
      count: 1,
    }),
  },
  {
    title: 'Severity',
    width: 150,
    property: 'severity',
    transformValue: sentenceCase,
  },
  {
    title: 'GWY Status',
    width: 150,
    component: ItemListComponent,
    props: result => ({
      result,
      items: sortBy(result.gwStatus),
    }),
  },
  {
    title: 'Status',
    width: 80,
    component: StatusToggle,
    props: result => ({
      result,
      alert: result,
    }),
  },
  {
    title: '',
    component: DeleteTrigger,
    width: 50,
    props: result => ({
      result,
      alert: result,
    }),
  },
];

export default contentCells;
