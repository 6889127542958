<script>
import includes from 'lodash/includes';
import { mapActions } from 'vuex';
import { MuiModal } from '@emobg/motion-ui/v1';
import permissionsMixin from '@domains/Main/mixins/permissionsMixin';
import carRental from '../../../store/CarrentalModuleMap';

import { CARRENTAL_PERMISSIONS } from '../../../const/permissions';

export default {
  name: 'DeleteTrigger',
  components: {
    MuiModal,
  },
  mixins: [permissionsMixin],
  props: {
    alert: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      isModalOpen: false,
      modalConfig: {
        title: 'Confirm deletion',
        header: {
          class: 'pl-3',
        },
      },
    };
  },
  computed: {
    isDisabled() {
      return !includes(this.permissions, CARRENTAL_PERMISSIONS.configurableAlertsDeleteTrigger);
    },
  },
  methods: {
    ...mapActions(carRental.alertsTrigger.list, [
      'deleteAlertTrigger',
    ]),
    updateTriggerStatus() {
      this.deleteAlertTrigger({ triggerUuid: this.alert.id });
      this.closeModal();
    },
    closeModal() {
      this.isModalOpen = false;
    },
  },
};
</script>

<template>
  <div
    class="d-flex justify-content-end"
    data-test-id="delete_trigger"
  >
    <MuiModal
      v-model="isModalOpen"
      data-test-id="modal"
      v-bind="modalConfig"
      @close-modal="closeModal"
    >
      <div
        slot="body"
        data-test-id="list-cell-delete-feedback"
        class="py-3"
      >
        The already triggered alerts will be deleted as well!
      </div>
      <div
        slot="footer"
        class="d-flex justify-content-end p-3"
      >
        <ui-button
          data-test-id="confirm-button"
          class="mr-4"
          @clickbutton="updateTriggerStatus"
        >
          Confirm
        </ui-button>

        <ui-button
          data-test-id="cancel-button"
          :color="GRAYSCALE.white"
          class="emobg-color-white-contrast"
          @clickbutton="closeModal"
        >
          Cancel
        </ui-button>
      </div>
    </MuiModal>

    <ui-button
      data-test-id="delete-button"
      :disabled="isDisabled"
      :face="FACES.outline"
      :color="GRAYSCALE.ink"
      square
      @clickbutton="isModalOpen = true"
    >
      <ui-icon
        :size="ICONS_SIZES.small"
        :color="GRAYSCALE.black"
        :disabled="isDisabled"
        :icon="ICONS.trash"
      />
    </ui-button>
  </div>
</template>
