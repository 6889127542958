var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "RouterLink",
    {
      staticClass: "emobg-link-primary emobg-body-2",
      attrs: {
        to: {
          name: _vm.services.alertsTrigger.details,
          params: {
            triggerUuid: _vm.result.id,
          },
        },
        "data-test-id": "trigger-link",
      },
    },
    [
      _c(
        "ui-tooltip",
        { key: _vm.triggerName, attrs: { tooltip: _vm.triggerName } },
        [_vm._v(" " + _vm._s(_vm.triggerName) + " ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }