export const ALERT_SEVERITIES = ['low', 'moderate', 'critical'];

export const TRIGGERS = [
  { value: 'in', label: 'Enter geofence' },
  { value: 'out', label: 'Exit geofence' },
];

export const TRIGGER_TYPES = Object.freeze({
  in: 'in',
  out: 'out',
});

export const GWY_STATUS = ['LS', 'NV', 'ON', 'OR', 'RM', 'RR', 'SL', 'ST', 'TC'];

export const CATEGORY = new RegExp('^$|([A-Z]){4}');
