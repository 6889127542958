var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "d-flex justify-content-end",
      attrs: { "data-test-id": "delete_trigger" },
    },
    [
      _c(
        "MuiModal",
        _vm._b(
          {
            attrs: { "data-test-id": "modal" },
            on: { "close-modal": _vm.closeModal },
            model: {
              value: _vm.isModalOpen,
              callback: function ($$v) {
                _vm.isModalOpen = $$v
              },
              expression: "isModalOpen",
            },
          },
          "MuiModal",
          _vm.modalConfig,
          false
        ),
        [
          _c(
            "div",
            {
              staticClass: "py-3",
              attrs: {
                slot: "body",
                "data-test-id": "list-cell-delete-feedback",
              },
              slot: "body",
            },
            [_vm._v(" The already triggered alerts will be deleted as well! ")]
          ),
          _c(
            "div",
            {
              staticClass: "d-flex justify-content-end p-3",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "ui-button",
                {
                  staticClass: "mr-4",
                  attrs: { "data-test-id": "confirm-button" },
                  on: { clickbutton: _vm.updateTriggerStatus },
                },
                [_vm._v(" Confirm ")]
              ),
              _c(
                "ui-button",
                {
                  staticClass: "emobg-color-white-contrast",
                  attrs: {
                    "data-test-id": "cancel-button",
                    color: _vm.GRAYSCALE.white,
                  },
                  on: { clickbutton: _vm.closeModal },
                },
                [_vm._v(" Cancel ")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "ui-button",
        {
          attrs: {
            "data-test-id": "delete-button",
            disabled: _vm.isDisabled,
            face: _vm.FACES.outline,
            color: _vm.GRAYSCALE.ink,
            square: "",
          },
          on: {
            clickbutton: function ($event) {
              _vm.isModalOpen = true
            },
          },
        },
        [
          _c("ui-icon", {
            attrs: {
              size: _vm.ICONS_SIZES.small,
              color: _vm.GRAYSCALE.black,
              disabled: _vm.isDisabled,
              icon: _vm.ICONS.trash,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }