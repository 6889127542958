<script>
import { FALLBACK_MESSAGE } from '@emobg/web-utils';
import services from '../../../router/ServicesRouterMap';

export default {
  name: 'TriggerLink',
  props: {
    result: {
      type: Object,
      default: () => ({}),
      required: true,
    },
  },
  computed: {
    triggerName() {
      return this.result.name || FALLBACK_MESSAGE.dash;
    },
  },
  created() {
    this.services = services;
  },
};
</script>
<template>
  <RouterLink
    :to="{
      name: services.alertsTrigger.details,
      params: {
        triggerUuid: result.id
      }
    }"
    class="emobg-link-primary emobg-body-2"
    data-test-id="trigger-link"
  >
    <ui-tooltip
      :key="triggerName"
      :tooltip="triggerName"
    >
      {{ triggerName }}
    </ui-tooltip>
  </RouterLink>
</template>
