<script>
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import toString from 'lodash/toString';
import includes from 'lodash/includes';
import uniq from 'lodash/uniq';
import map from 'lodash/map';
import without from 'lodash/without';
import trim from 'lodash/trim';
import clone from 'lodash/clone';
import {
  mapActions, mapGetters, mapMutations, mapState,
} from 'vuex';
import {
  MuiInputText,
  MuiSelect,
  MuiValidationWrapper,
  Validate,
} from '@emobg/motion-ui/v1';
import { navigationErrorHandler, SECONDS_PER_MINUTE, sentenceCase } from '@emobg/web-utils';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { NOTIFICATION_TYPES } from '@/constants/notifications';
import { GoogleMapZonesComponent } from '@/components';
import { PATTERN_INPUT_VALIDATIONS } from '@/utils';
import SidePanel from '../../components/SidePanel';

import services from '../../router/ServicesRouterMap';

import {
  ALERT_SEVERITIES,
  CATEGORY,
  GWY_STATUS,
  TRIGGER_TYPES,
  TRIGGERS,
} from './const/alert';
import carRental from '../../store/CarrentalModuleMap';
import { scopes } from './store/NewAlertTriggerModule';

export default {
  name: 'AddAlertTriggerView',
  components: {
    GoogleMapZonesComponent,
    MuiInputText,
    MuiValidationWrapper,
    MuiSelect,
    SidePanel,
  },
  directives: { Validate },
  data() {
    return {
      googleMapsKey: process.env.GOOGLE_MAPS_KEY,
      address: {},
      isSidebarOpen: false,
      isAddingNewZone: false,
      isEditingNewZone: false,
      isTriggerFormValid: false,
      newZone: {},
      createdZone: {},
      editedZone: {},
      category: '',
      triggerName: '',
      tolerance: 0,
      payload: {
        name: '',
        description: '',
        severity: 'low',
        operatorId: '',
        geofences: [],
        rules: {
          trigger: 'in',
          categories: [],
          stations: [],
          gwStatus: [],
          tolerance: 0,
        },
      },
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.app.userAccount, {
      activeOperatorUuid: state => get(state, 'operators.active.uuid'),
    }),
    ...mapState(carRental.alertsTrigger.trigger, {
      isAlertDataLoading: state => get(state, 'alertTriggerData.STATUS.LOADING'),
      geofences: state => get(state, 'alertTriggerData.data.geofences', []),
      alertTriggerData: state => get(state, 'alertTriggerData.data', {}),
      newAlertTriggerStatus: state => get(state, 'newAlertTrigger.STATUS'),
    }),
    ...mapState(carRental.alertsTrigger.map, {
      mapZoom: state => state.zoom,
      mapCenter: state => state.center,
    }),
    ...mapGetters(carRental.alertsTrigger.trigger, ['getStations']),
    isZoneValid() {
      return !isEmpty(this.newZone)
        && this.newZone.name
        && this.newZone.name.length > 2
        && (
          get(this, 'newZone.polygon', []).length
        || get(this, 'editedZone.polygon', []).length
        );
    },
    isTriggerValid() {
      return this.isZoneValid && this.isTriggerFormValid;
    },
  },
  watch: {
    alertTriggerData() {
      const {
        name, description, severity, rules,
      } = this.alertTriggerData;
      this.category = toString(get(rules, 'categories', []));
      this.tolerance = (get(rules, 'tolerance') || 0) / SECONDS_PER_MINUTE;
      this.payload = {
        name,
        description,
        severity,
        rules: {
          trigger: get(rules, 'trigger', 'in'),
          stations: get(rules, 'stations', []),
          gwStatus: get(rules, 'gwStatus', []),
          categories: [],
        },
      };
    },
    activeOperatorUuid: {
      handler() {
        this.getStationsList({
          query: {
            operator: this.activeOperatorUuid,
          },
        });
      },
      immediate: true,
    },
    category(newValue) {
      if (this.triggerUuid) {
        return;
      }

      const hasComma = includes(newValue, ',');

      const isValidCategory = this.CATEGORY.test(newValue);
      if (hasComma && isValidCategory) {
        this.payload.rules.categories = uniq([...this.payload.rules.categories, newValue.slice(0, 4)]);
        this.category = '';
      }
    },
  },
  created() {
    this.triggerUuid = get(this, '$route.params.triggerUuid');
    this.startTime = Date.now();

    this.CATEGORY = CATEGORY;
    this.ALERT_SEVERITIES = ALERT_SEVERITIES;
    this.GWY_STATUS = GWY_STATUS;
    this.TRIGGERS = TRIGGERS;
    this.TRIGGER_TYPES = TRIGGER_TYPES;
    this.PATTERN_INPUT_VALIDATIONS = PATTERN_INPUT_VALIDATIONS;
  },
  async mounted() {
    if (this.triggerUuid) {
      await this.getAlertTriggerData(this.triggerUuid);
    }
  },
  beforeDestroy() {
    this.clearData({ scopes: [scopes.alertTriggerData, scopes.stations] });
  },
  methods: {
    clone,
    ...mapActions(carRental.alertsTrigger.trigger, [
      'getAlertTriggerData',
      'postAlertTrigger',
      'getStationsList',
    ]),
    ...mapMutations(carRental.alertsTrigger.map, [
      'setZoom',
      'setCenter',
    ]),
    ...mapMutations(carRental.alertsTrigger.trigger, ['clearData']),
    confirmAction() {
      this.isAddingNewZone = false;
      this.isSidebarOpen = false;
      this.isEditingNewZone = false;
      this.createdZone = { ...this.newZone, ...this.editedZone };
    },
    updateApiPayload(zone) {
      this.editedZone = { ...this.newZone, ...zone };
    },
    async createAlertTrigger() {
      this.payload.operatorId = this.activeOperatorUuid;
      this.payload.name = trim(this.payload.name);
      this.payload.geofences = [this.createdZone];
      this.payload.rules.tolerance = this.tolerance * SECONDS_PER_MINUTE;

      await this.postAlertTrigger(this.payload);
      if (!this.newAlertTriggerStatus.ERROR) {
        this.$notify({
          type: NOTIFICATION_TYPES.success,
          message: 'New alert trigger has been successfully created!',
        });
        this.navigateToAlertList();
      }
    },
    removeCategory(category) {
      this.payload.rules.categories = without(this.payload.rules.categories, category);
    },
    navigateToAlertList() {
      this.$router.push({ name: services.alertsTrigger.index }).catch(navigationErrorHandler);
    },
    openZoneDrawing() {
      this.isSidebarOpen = true;
      this.isAddingNewZone = true;
    },
    updateZoneDrawing() {
      this.isSidebarOpen = true;
      this.isAddingNewZone = false;
      this.isEditingNewZone = true;
    },
    cancelZoneDrawing() {
      if (!this.isEditingNewZone) {
        this.newZone = {};
      }
      this.isSidebarOpen = false;
      this.isAddingNewZone = false;
      this.isEditingNewZone = false;
    },
    isEmpty,
    map,
    sentenceCase,
  },
};
</script>

<template>
  <div
    class="AddAlertTriggerView"
    data-test-id="add_alert_trigger-view"
  >
    <SidePanel
      v-if="isSidebarOpen"
      data-test-id="draw_new_zone-side_panel"
    >
      <template slot="content">
        <GoogleMapZonesComponent
          data-test-id="content-map"
          :address="address.location"
          :zones="[clone(createdZone)]"
          :is-editing="isAddingNewZone || isEditingNewZone"
          :center="mapCenter"
          :zoom="mapZoom"
          @add-shape="updateApiPayload"
          @update-shape="updateApiPayload"
        />
        <ui-location-input
          v-if="!isZoneValid"
          :gkey="googleMapsKey"
          data-test-id="content-location_search"
          placeholder="Search location"
          class="position-absolute z-index-30"
          @changeplace="({ detail }) => address = detail"
        />
      </template>

      <template slot="sidebar-top">
        <br><br><br>
      </template>

      <template slot="sidebar">
        <div
          class="p-3"
          data-test-id="sidebar"
        >
          <div class="row">
            <div class="col-12">
              <h2
                data-test-id="setup-trigger-add_change_zone"
                class="emobg-font-medium emobg-font-weight-bold mb-2"
              >
                {{ isEditingNewZone ? 'Change' : 'Draw new' }} geofence
              </h2>
              <hr class="emobg-border-bottom-2 emobg-border-color-ground mb-2">
            </div>

            <div class="col-12">
              <label>Zone name</label>
              <MuiInputText
                v-model="newZone.name"
                v-validate.blur="{
                  isMinLength: 3
                }"
                data-test-id="zone_name-input"
                placeholder="Please introduce the zone name"
                name="name"
                class="py-2 w-100"
              />
            </div>

            <div class="col-12 text-right">
              <ui-button
                data-test-id="cancel-button"
                :color="GRAYSCALE.white"
                class="mr-2 emobg-color-white-contrast"
                @clickbutton="cancelZoneDrawing"
              >
                Cancel
              </ui-button>
              <ui-button
                data-test-id="confirm_create-button"
                :disabled="!isZoneValid"
                @clickbutton="confirmAction"
              >
                {{ isEditingNewZone ? 'Confirm' : 'Create' }}
              </ui-button>
            </div>
          </div>
        </div>
      </template>
    </SidePanel>

    <div
      v-else
      class="emobg-background-color-white pl-3 pr-2 px-lg-5"
      data-test-id="setup_trigger"
    >
      <ui-loader
        v-if="isAlertDataLoading"
        data-test-id="loader"
        class="center"
      />
      <div v-else>
        <h2
          class="d-inline-block mt-4 mb-3 pb-3 w-100 emobg-border-bottom-1 emobg-border-color-ink-lighter"
          data-test-id="page_title"
        >
          {{ triggerUuid ? `Alert trigger details ${alertTriggerData.name}` : 'Add new alert trigger' }}
        </h2>
        <div class="row pb-4">
          <MuiValidationWrapper
            class="col-4"
            @areAllValid="valid => isTriggerFormValid = valid"
          >
            <div class="w-100 emobg-font-weight-bold emobg-font-default mb-2">
              Alert name
            </div>
            <div class="w-100 emobg-font-weight-bold emobg-font-small mb-1">
              Name of the alert *
            </div>
            <MuiInputText
              v-model="payload.name"
              v-validate.blur="{
                isMinLength: 3,
                isRequired: true,
              }"
              :disabled="!!triggerUuid"
              data-test-id="alert_name-input"
              name="triggerName"
              placeholder="Introduce name"
              class="mb-2"
            />
            <div class="w-100 emobg-font-weight-bold emobg-font-small mb-1 mt-2">
              Description *
            </div>
            <MuiInputText
              v-model="payload.description"
              v-validate.blur="{
                isMinLength: 3,
                isRequired: true,
              }"
              :disabled="!!triggerUuid"
              data-test-id="description-input"
              placeholder="Introduce description"
              name="triggerDescription"
              class="mb-2"
            />
            <div class="w-100 emobg-font-weight-bold emobg-font-default mb-2 mt-4">
              Severity
            </div>
            <div class="row mb-4">
              <div
                v-for="severity in ALERT_SEVERITIES"
                :key="severity"
                class="col-4"
              >
                <div class="p-1 w-100 emobg-border-1 emobg-background-color-white emobg-border-color-ground emobg-border-radius-small">
                  <ui-radio
                    :value="payload.severity"
                    :data-test-id="`severity-${severity}`"
                    :caption="sentenceCase(severity)"
                    :option="severity"
                    :name="severity"
                    :disabled="!!triggerUuid"
                    @changevalue="() => payload.severity = severity"
                  />
                </div>
              </div>
            </div>
            <div class="w-100 emobg-font-weight-bold emobg-font-default mb-2">
              Select trigger
            </div>
            <div class="row mb-4">
              <div
                v-for="trigger in TRIGGERS"
                :key="trigger"
                class="col-6"
              >
                <div class="p-1 w-100 emobg-border-1 emobg-background-color-white emobg-border-color-ground emobg-border-radius-small">
                  <ui-radio
                    :value="payload.rules.trigger"
                    :caption="trigger.label"
                    :option="trigger.value"
                    :name="trigger.value"
                    :disabled="!!triggerUuid"
                    data-test-id="select_trigger"
                    @changevalue="() => payload.rules.trigger = trigger.value"
                  />
                </div>
              </div>
            </div>
            <div class="w-100 emobg-font-weight-bold emobg-font-default mb-2">
              Tolerance time
            </div>
            <div class="d-flex align-items-center mb-1">
              <span class="emobg-font-weight-bold emobg-font-small">{{ `Trigger alert after being ${payload.rules.trigger === TRIGGER_TYPES.in ? 'inside' : 'outside'} the geofence (Minutes)` }}</span>
              <ui-tooltip
                :tooltip="`If the vehicle remains ${payload.rules.trigger === TRIGGER_TYPES.in ? 'inside' : 'outside'} of the geofence this amount of time, trigger the alert`"
                class="d-flex ml-1"
              >
                <ui-icon
                  :icon="ICONS.infoFull"
                  :size="ICONS_SIZES.xSmall"
                  :color="GRAYSCALE.inkLight"
                />
              </ui-tooltip>
            </div>
            <MuiInputText
              v-model.number="tolerance"
              v-validate="{
                isRequired: true,
                isPattern: PATTERN_INPUT_VALIDATIONS.wholeNumber,
              }"
              :disabled="!!triggerUuid"
              data-test-id="tolerance-input"
              name="tolerance"
            />
            <div class="w-100 emobg-font-weight-bold emobg-font-default mt-4 mb-2">
              Choose segment of vehicles
            </div>
            <div class="d-flex align-items-center mb-1">
              <span class="emobg-font-weight-bold emobg-font-small">Categories</span>
              <ui-tooltip
                tooltip="Please, enter four UPPERCASE characters for vehicle category"
                data-test-id="category-tooltip"
                class="d-flex ml-1"
              >
                <ui-icon
                  :icon="ICONS.infoFull"
                  :size="ICONS_SIZES.xSmall"
                  :color="GRAYSCALE.inkLight"
                />
              </ui-tooltip>
            </div>
            <MuiInputText
              v-model="category"
              v-validate.blur="{
                isPattern: {
                  pattern: CATEGORY,
                  label: 'Category is Invalid. Enter 4 UPPERCASE characters'
                }
              }"
              :disabled="!!triggerUuid"
              data-test-id="category-input"
              name="category"
              placeholder="All vehicle categories"
            />
            <ui-badge
              v-for="(selectedCategory, index) in payload.rules.categories"
              :key="index"
              :color="GRAYSCALE.ground"
              class="mr-2 my-1 d-inline-flex justify-content-between align-content-center"
            >
              <div class="d-flex align-items-center">
                {{ selectedCategory }}
                <ui-button
                  :face="FACES.text"
                  square
                  data-test-id="remove_category-button"
                  @clickbutton="removeCategory(selectedCategory)"
                >
                  <ui-icon
                    :icon="ICONS.removeFilled"
                    :size="ICONS_SIZES.xSmall"
                    class="mr-n1"
                  />
                </ui-button>
              </div>
            </ui-badge>
            <div class="w-100 emobg-font-weight-bold emobg-font-small mb-1 mt-2">
              Stations
            </div>
            <MuiSelect
              v-model="payload.rules.stations"
              :options="getStations"
              :disabled="!!triggerUuid"
              multiple
              data-test-id="stations-select"
              placeholder="All stations"
              class="w-100 mb-2"
              name="stations"
            />
            <div class="emobg-font-weight-bold emobg-font-small mb-1">
              GWY status
            </div>
            <MuiSelect
              v-model="payload.rules.gwStatus"
              :options="map(GWY_STATUS, item => ({
                value: item,
                label: item,
              }))"
              :disabled="!!triggerUuid"
              multiple
              data-test-id="gwstatus-select"
              placeholder="All GWY statuses"
              class="w-100 mb-4"
              name="stations"
            />
            <ui-alert :color="COLORS.primary">
              <div class="d-flex align-items-center">
                <ui-icon
                  :icon="ICONS.infoFull"
                  :color="COLORS.primary"
                  :size="ICONS_SIZES.small"
                  class="mr-2"
                />
                <span class="emobg-font-small">
                  Once you create the alert you will not be able to edit it
                </span>
              </div>
            </ui-alert>
            <div class="mt-2 d-flex justify-content-end">
              <ui-button
                data-test-id="close_cancel-button"
                :face="FACES.text"
                @clickbutton="navigateToAlertList"
              >
                {{ triggerUuid ? 'Close' : 'Cancel' }}
              </ui-button>
              <ui-button
                v-if="!triggerUuid"
                :loading="newAlertTriggerStatus.LOADING"
                :disabled="!isTriggerValid || triggerUuid"
                data-test-id="create-button"
                class="ml-2"
                @clickbutton="createAlertTrigger"
              >
                Create alert
              </ui-button>
            </div>
          </MuiValidationWrapper>
          <div class="col-8 d-flex flex-column">
            <div class="w-100 emobg-font-weight-bold emobg-font-default mb-2">
              Draw new geofence on the map
            </div>
            <ui-button
              v-if="isEmpty(createdZone)"
              data-test-id="draw_zone-button"
              :face="FACES.outline"
              :disabled="!!triggerUuid"
              class="mb-3 d-inline-block"
              @clickbutton="openZoneDrawing"
            >
              Draw new geofence
            </ui-button>

            <ui-button
              v-else
              data-test-id="change_zone-button"
              :face="FACES.outline"
              class="mb-3 d-inline-block"
              @clickbutton="updateZoneDrawing"
            >
              Change alert geofence
            </ui-button>
            <GoogleMapZonesComponent
              v-if="!isSidebarOpen"
              :zones="triggerUuid ? geofences : [clone(createdZone)]"
              class="h-100"
              data-test-id="setup-trigger-map"
              is-static
              @zoomLevel="setZoom($event)"
              @center="setCenter($event)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
