<script>
import includes from 'lodash/includes';
import { mapActions } from 'vuex';
import permissionsMixin from '@domains/Main/mixins/permissionsMixin';

import carRental from '../../../store/CarrentalModuleMap';
import { CARRENTAL_PERMISSIONS } from '../../../const/permissions';

export default {
  name: 'StatusToggle',
  mixins: [permissionsMixin],
  props: {
    alert: {
      type: Object,
      default: () => ({}),
    },
  },
  created() {
    this.CARRENTAL_PERMISSIONS = CARRENTAL_PERMISSIONS;
  },
  methods: {
    ...mapActions(carRental.alertsTrigger.list, [
      'putAlertTrigger',
    ]),
    includes,
    updateTriggerStatus(value) {
      this.putAlertTrigger({
        data: {
          id: this.alert.id,
          isActive: value,
        },
      });
    },
  },
};
</script>

<template>
  <ui-toggle
    :value="alert.isActive"
    data-test-id="status_toggle"
    :color="COLORS.success"
    :background="GRAYSCALE.none"
    :disabled="!includes(permissions, CARRENTAL_PERMISSIONS.configurableAlertsUpdateStatus)"
    name="statusToggle"
    class="mb-2 d-block"
    @changevalue="({ detail }) => updateTriggerStatus(detail)"
  />
</template>
