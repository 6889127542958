var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("ui-toggle", {
    staticClass: "mb-2 d-block",
    attrs: {
      value: _vm.alert.isActive,
      "data-test-id": "status_toggle",
      color: _vm.COLORS.success,
      background: _vm.GRAYSCALE.none,
      disabled: !_vm.includes(
        _vm.permissions,
        _vm.CARRENTAL_PERMISSIONS.configurableAlertsUpdateStatus
      ),
      name: "statusToggle",
    },
    on: { changevalue: ({ detail }) => _vm.updateTriggerStatus(detail) },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }