var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "AddAlertTriggerView",
      attrs: { "data-test-id": "add_alert_trigger-view" },
    },
    [
      _vm.isSidebarOpen
        ? _c(
            "SidePanel",
            { attrs: { "data-test-id": "draw_new_zone-side_panel" } },
            [
              _c(
                "template",
                { slot: "content" },
                [
                  _c("GoogleMapZonesComponent", {
                    attrs: {
                      "data-test-id": "content-map",
                      address: _vm.address.location,
                      zones: [_vm.clone(_vm.createdZone)],
                      "is-editing": _vm.isAddingNewZone || _vm.isEditingNewZone,
                      center: _vm.mapCenter,
                      zoom: _vm.mapZoom,
                    },
                    on: {
                      "add-shape": _vm.updateApiPayload,
                      "update-shape": _vm.updateApiPayload,
                    },
                  }),
                  !_vm.isZoneValid
                    ? _c("ui-location-input", {
                        staticClass: "position-absolute z-index-30",
                        attrs: {
                          gkey: _vm.googleMapsKey,
                          "data-test-id": "content-location_search",
                          placeholder: "Search location",
                        },
                        on: {
                          changeplace: ({ detail }) => (_vm.address = detail),
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _c("template", { slot: "sidebar-top" }, [
                _c("br"),
                _c("br"),
                _c("br"),
              ]),
              _c("template", { slot: "sidebar" }, [
                _c(
                  "div",
                  { staticClass: "p-3", attrs: { "data-test-id": "sidebar" } },
                  [
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-12" }, [
                        _c(
                          "h2",
                          {
                            staticClass:
                              "emobg-font-medium emobg-font-weight-bold mb-2",
                            attrs: {
                              "data-test-id": "setup-trigger-add_change_zone",
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.isEditingNewZone ? "Change" : "Draw new"
                                ) +
                                " geofence "
                            ),
                          ]
                        ),
                        _c("hr", {
                          staticClass:
                            "emobg-border-bottom-2 emobg-border-color-ground mb-2",
                        }),
                      ]),
                      _c(
                        "div",
                        { staticClass: "col-12" },
                        [
                          _c("label", [_vm._v("Zone name")]),
                          _c("MuiInputText", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate.blur",
                                value: {
                                  isMinLength: 3,
                                },
                                expression:
                                  "{\n                isMinLength: 3\n              }",
                                modifiers: { blur: true },
                              },
                            ],
                            staticClass: "py-2 w-100",
                            attrs: {
                              "data-test-id": "zone_name-input",
                              placeholder: "Please introduce the zone name",
                              name: "name",
                            },
                            model: {
                              value: _vm.newZone.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.newZone, "name", $$v)
                              },
                              expression: "newZone.name",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-12 text-right" },
                        [
                          _c(
                            "ui-button",
                            {
                              staticClass: "mr-2 emobg-color-white-contrast",
                              attrs: {
                                "data-test-id": "cancel-button",
                                color: _vm.GRAYSCALE.white,
                              },
                              on: { clickbutton: _vm.cancelZoneDrawing },
                            },
                            [_vm._v(" Cancel ")]
                          ),
                          _c(
                            "ui-button",
                            {
                              attrs: {
                                "data-test-id": "confirm_create-button",
                                disabled: !_vm.isZoneValid,
                              },
                              on: { clickbutton: _vm.confirmAction },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.isEditingNewZone ? "Confirm" : "Create"
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]
                ),
              ]),
            ],
            2
          )
        : _c(
            "div",
            {
              staticClass: "emobg-background-color-white pl-3 pr-2 px-lg-5",
              attrs: { "data-test-id": "setup_trigger" },
            },
            [
              _vm.isAlertDataLoading
                ? _c("ui-loader", {
                    staticClass: "center",
                    attrs: { "data-test-id": "loader" },
                  })
                : _c("div", [
                    _c(
                      "h2",
                      {
                        staticClass:
                          "d-inline-block mt-4 mb-3 pb-3 w-100 emobg-border-bottom-1 emobg-border-color-ink-lighter",
                        attrs: { "data-test-id": "page_title" },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.triggerUuid
                                ? `Alert trigger details ${_vm.alertTriggerData.name}`
                                : "Add new alert trigger"
                            ) +
                            " "
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "row pb-4" },
                      [
                        _c(
                          "MuiValidationWrapper",
                          {
                            staticClass: "col-4",
                            on: {
                              areAllValid: (valid) =>
                                (_vm.isTriggerFormValid = valid),
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "w-100 emobg-font-weight-bold emobg-font-default mb-2",
                              },
                              [_vm._v(" Alert name ")]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "w-100 emobg-font-weight-bold emobg-font-small mb-1",
                              },
                              [_vm._v(" Name of the alert * ")]
                            ),
                            _c("MuiInputText", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate.blur",
                                  value: {
                                    isMinLength: 3,
                                    isRequired: true,
                                  },
                                  expression:
                                    "{\n              isMinLength: 3,\n              isRequired: true,\n            }",
                                  modifiers: { blur: true },
                                },
                              ],
                              staticClass: "mb-2",
                              attrs: {
                                disabled: !!_vm.triggerUuid,
                                "data-test-id": "alert_name-input",
                                name: "triggerName",
                                placeholder: "Introduce name",
                              },
                              model: {
                                value: _vm.payload.name,
                                callback: function ($$v) {
                                  _vm.$set(_vm.payload, "name", $$v)
                                },
                                expression: "payload.name",
                              },
                            }),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "w-100 emobg-font-weight-bold emobg-font-small mb-1 mt-2",
                              },
                              [_vm._v(" Description * ")]
                            ),
                            _c("MuiInputText", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate.blur",
                                  value: {
                                    isMinLength: 3,
                                    isRequired: true,
                                  },
                                  expression:
                                    "{\n              isMinLength: 3,\n              isRequired: true,\n            }",
                                  modifiers: { blur: true },
                                },
                              ],
                              staticClass: "mb-2",
                              attrs: {
                                disabled: !!_vm.triggerUuid,
                                "data-test-id": "description-input",
                                placeholder: "Introduce description",
                                name: "triggerDescription",
                              },
                              model: {
                                value: _vm.payload.description,
                                callback: function ($$v) {
                                  _vm.$set(_vm.payload, "description", $$v)
                                },
                                expression: "payload.description",
                              },
                            }),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "w-100 emobg-font-weight-bold emobg-font-default mb-2 mt-4",
                              },
                              [_vm._v(" Severity ")]
                            ),
                            _c(
                              "div",
                              { staticClass: "row mb-4" },
                              _vm._l(_vm.ALERT_SEVERITIES, function (severity) {
                                return _c(
                                  "div",
                                  { key: severity, staticClass: "col-4" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "p-1 w-100 emobg-border-1 emobg-background-color-white emobg-border-color-ground emobg-border-radius-small",
                                      },
                                      [
                                        _c("ui-radio", {
                                          attrs: {
                                            value: _vm.payload.severity,
                                            "data-test-id": `severity-${severity}`,
                                            caption: _vm.sentenceCase(severity),
                                            option: severity,
                                            name: severity,
                                            disabled: !!_vm.triggerUuid,
                                          },
                                          on: {
                                            changevalue: () =>
                                              (_vm.payload.severity = severity),
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                )
                              }),
                              0
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "w-100 emobg-font-weight-bold emobg-font-default mb-2",
                              },
                              [_vm._v(" Select trigger ")]
                            ),
                            _c(
                              "div",
                              { staticClass: "row mb-4" },
                              _vm._l(_vm.TRIGGERS, function (trigger) {
                                return _c(
                                  "div",
                                  { key: trigger, staticClass: "col-6" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "p-1 w-100 emobg-border-1 emobg-background-color-white emobg-border-color-ground emobg-border-radius-small",
                                      },
                                      [
                                        _c("ui-radio", {
                                          attrs: {
                                            value: _vm.payload.rules.trigger,
                                            caption: trigger.label,
                                            option: trigger.value,
                                            name: trigger.value,
                                            disabled: !!_vm.triggerUuid,
                                            "data-test-id": "select_trigger",
                                          },
                                          on: {
                                            changevalue: () =>
                                              (_vm.payload.rules.trigger =
                                                trigger.value),
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                )
                              }),
                              0
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "w-100 emobg-font-weight-bold emobg-font-default mb-2",
                              },
                              [_vm._v(" Tolerance time ")]
                            ),
                            _c(
                              "div",
                              { staticClass: "d-flex align-items-center mb-1" },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "emobg-font-weight-bold emobg-font-small",
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        `Trigger alert after being ${
                                          _vm.payload.rules.trigger ===
                                          _vm.TRIGGER_TYPES.in
                                            ? "inside"
                                            : "outside"
                                        } the geofence (Minutes)`
                                      )
                                    ),
                                  ]
                                ),
                                _c(
                                  "ui-tooltip",
                                  {
                                    staticClass: "d-flex ml-1",
                                    attrs: {
                                      tooltip: `If the vehicle remains ${
                                        _vm.payload.rules.trigger ===
                                        _vm.TRIGGER_TYPES.in
                                          ? "inside"
                                          : "outside"
                                      } of the geofence this amount of time, trigger the alert`,
                                    },
                                  },
                                  [
                                    _c("ui-icon", {
                                      attrs: {
                                        icon: _vm.ICONS.infoFull,
                                        size: _vm.ICONS_SIZES.xSmall,
                                        color: _vm.GRAYSCALE.inkLight,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c("MuiInputText", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: {
                                    isRequired: true,
                                    isPattern:
                                      _vm.PATTERN_INPUT_VALIDATIONS.wholeNumber,
                                  },
                                  expression:
                                    "{\n              isRequired: true,\n              isPattern: PATTERN_INPUT_VALIDATIONS.wholeNumber,\n            }",
                                },
                              ],
                              attrs: {
                                disabled: !!_vm.triggerUuid,
                                "data-test-id": "tolerance-input",
                                name: "tolerance",
                              },
                              model: {
                                value: _vm.tolerance,
                                callback: function ($$v) {
                                  _vm.tolerance = _vm._n($$v)
                                },
                                expression: "tolerance",
                              },
                            }),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "w-100 emobg-font-weight-bold emobg-font-default mt-4 mb-2",
                              },
                              [_vm._v(" Choose segment of vehicles ")]
                            ),
                            _c(
                              "div",
                              { staticClass: "d-flex align-items-center mb-1" },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "emobg-font-weight-bold emobg-font-small",
                                  },
                                  [_vm._v("Categories")]
                                ),
                                _c(
                                  "ui-tooltip",
                                  {
                                    staticClass: "d-flex ml-1",
                                    attrs: {
                                      tooltip:
                                        "Please, enter four UPPERCASE characters for vehicle category",
                                      "data-test-id": "category-tooltip",
                                    },
                                  },
                                  [
                                    _c("ui-icon", {
                                      attrs: {
                                        icon: _vm.ICONS.infoFull,
                                        size: _vm.ICONS_SIZES.xSmall,
                                        color: _vm.GRAYSCALE.inkLight,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c("MuiInputText", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate.blur",
                                  value: {
                                    isPattern: {
                                      pattern: _vm.CATEGORY,
                                      label:
                                        "Category is Invalid. Enter 4 UPPERCASE characters",
                                    },
                                  },
                                  expression:
                                    "{\n              isPattern: {\n                pattern: CATEGORY,\n                label: 'Category is Invalid. Enter 4 UPPERCASE characters'\n              }\n            }",
                                  modifiers: { blur: true },
                                },
                              ],
                              attrs: {
                                disabled: !!_vm.triggerUuid,
                                "data-test-id": "category-input",
                                name: "category",
                                placeholder: "All vehicle categories",
                              },
                              model: {
                                value: _vm.category,
                                callback: function ($$v) {
                                  _vm.category = $$v
                                },
                                expression: "category",
                              },
                            }),
                            _vm._l(
                              _vm.payload.rules.categories,
                              function (selectedCategory, index) {
                                return _c(
                                  "ui-badge",
                                  {
                                    key: index,
                                    staticClass:
                                      "mr-2 my-1 d-inline-flex justify-content-between align-content-center",
                                    attrs: { color: _vm.GRAYSCALE.ground },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex align-items-center",
                                      },
                                      [
                                        _vm._v(
                                          " " + _vm._s(selectedCategory) + " "
                                        ),
                                        _c(
                                          "ui-button",
                                          {
                                            attrs: {
                                              face: _vm.FACES.text,
                                              square: "",
                                              "data-test-id":
                                                "remove_category-button",
                                            },
                                            on: {
                                              clickbutton: function ($event) {
                                                return _vm.removeCategory(
                                                  selectedCategory
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("ui-icon", {
                                              staticClass: "mr-n1",
                                              attrs: {
                                                icon: _vm.ICONS.removeFilled,
                                                size: _vm.ICONS_SIZES.xSmall,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                )
                              }
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "w-100 emobg-font-weight-bold emobg-font-small mb-1 mt-2",
                              },
                              [_vm._v(" Stations ")]
                            ),
                            _c("MuiSelect", {
                              staticClass: "w-100 mb-2",
                              attrs: {
                                options: _vm.getStations,
                                disabled: !!_vm.triggerUuid,
                                multiple: "",
                                "data-test-id": "stations-select",
                                placeholder: "All stations",
                                name: "stations",
                              },
                              model: {
                                value: _vm.payload.rules.stations,
                                callback: function ($$v) {
                                  _vm.$set(_vm.payload.rules, "stations", $$v)
                                },
                                expression: "payload.rules.stations",
                              },
                            }),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "emobg-font-weight-bold emobg-font-small mb-1",
                              },
                              [_vm._v(" GWY status ")]
                            ),
                            _c("MuiSelect", {
                              staticClass: "w-100 mb-4",
                              attrs: {
                                options: _vm.map(_vm.GWY_STATUS, (item) => ({
                                  value: item,
                                  label: item,
                                })),
                                disabled: !!_vm.triggerUuid,
                                multiple: "",
                                "data-test-id": "gwstatus-select",
                                placeholder: "All GWY statuses",
                                name: "stations",
                              },
                              model: {
                                value: _vm.payload.rules.gwStatus,
                                callback: function ($$v) {
                                  _vm.$set(_vm.payload.rules, "gwStatus", $$v)
                                },
                                expression: "payload.rules.gwStatus",
                              },
                            }),
                            _c(
                              "ui-alert",
                              { attrs: { color: _vm.COLORS.primary } },
                              [
                                _c(
                                  "div",
                                  { staticClass: "d-flex align-items-center" },
                                  [
                                    _c("ui-icon", {
                                      staticClass: "mr-2",
                                      attrs: {
                                        icon: _vm.ICONS.infoFull,
                                        color: _vm.COLORS.primary,
                                        size: _vm.ICONS_SIZES.small,
                                      },
                                    }),
                                    _c(
                                      "span",
                                      { staticClass: "emobg-font-small" },
                                      [
                                        _vm._v(
                                          " Once you create the alert you will not be able to edit it "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "mt-2 d-flex justify-content-end",
                              },
                              [
                                _c(
                                  "ui-button",
                                  {
                                    attrs: {
                                      "data-test-id": "close_cancel-button",
                                      face: _vm.FACES.text,
                                    },
                                    on: {
                                      clickbutton: _vm.navigateToAlertList,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.triggerUuid ? "Close" : "Cancel"
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                                !_vm.triggerUuid
                                  ? _c(
                                      "ui-button",
                                      {
                                        staticClass: "ml-2",
                                        attrs: {
                                          loading:
                                            _vm.newAlertTriggerStatus.LOADING,
                                          disabled:
                                            !_vm.isTriggerValid ||
                                            _vm.triggerUuid,
                                          "data-test-id": "create-button",
                                        },
                                        on: {
                                          clickbutton: _vm.createAlertTrigger,
                                        },
                                      },
                                      [_vm._v(" Create alert ")]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          2
                        ),
                        _c(
                          "div",
                          { staticClass: "col-8 d-flex flex-column" },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "w-100 emobg-font-weight-bold emobg-font-default mb-2",
                              },
                              [_vm._v(" Draw new geofence on the map ")]
                            ),
                            _vm.isEmpty(_vm.createdZone)
                              ? _c(
                                  "ui-button",
                                  {
                                    staticClass: "mb-3 d-inline-block",
                                    attrs: {
                                      "data-test-id": "draw_zone-button",
                                      face: _vm.FACES.outline,
                                      disabled: !!_vm.triggerUuid,
                                    },
                                    on: { clickbutton: _vm.openZoneDrawing },
                                  },
                                  [_vm._v(" Draw new geofence ")]
                                )
                              : _c(
                                  "ui-button",
                                  {
                                    staticClass: "mb-3 d-inline-block",
                                    attrs: {
                                      "data-test-id": "change_zone-button",
                                      face: _vm.FACES.outline,
                                    },
                                    on: { clickbutton: _vm.updateZoneDrawing },
                                  },
                                  [_vm._v(" Change alert geofence ")]
                                ),
                            !_vm.isSidebarOpen
                              ? _c("GoogleMapZonesComponent", {
                                  staticClass: "h-100",
                                  attrs: {
                                    zones: _vm.triggerUuid
                                      ? _vm.geofences
                                      : [_vm.clone(_vm.createdZone)],
                                    "data-test-id": "setup-trigger-map",
                                    "is-static": "",
                                  },
                                  on: {
                                    zoomLevel: function ($event) {
                                      return _vm.setZoom($event)
                                    },
                                    center: function ($event) {
                                      return _vm.setCenter($event)
                                    },
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }